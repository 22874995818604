
.flexboxcontainer3 {
    display: flex;
    position: relative;
    background-color: black;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-around;
    padding-top: 5%;
    align-items: center;
    padding-left: 20%;
    padding-right: 20%;
}

h2 {
    font-size: 250%;
    margin-bottom: 120px;
}

#form {
    display: flex;
    font-family: 'Manrope', sans-serif;
    align-items: center;  
    justify-content: center;
    align-self: center;  
    flex-direction: column; 
}

input {
    border: none; 
    width: 300px;
    height: 30px;
    margin: 6%;
}

textarea {
    font-family: 'Manrope', sans-serif;
    overflow-wrap: break-word;
    width: 300px;
    height: 100px;
    margin: 6%;
}

.sendButton {
    font-family: 'Manrope', sans-serif;
    font-weight: 100;
    border-radius: 10px;
    border-color: black;
    border-style: solid;
    background: whitesmoke;
    color:black;
    width: 80px;
    height: 30px;
    margin-top: 6%;
    margin-bottom: 100px;
}

.sendButton:hover {
    background: #783D19;
}

