/* Container styles */

.flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
}

.container {
    position: relative;
    width: 500px;
    margin: 20px;
    padding-top: 20px;
  }

  .container h1 {
    font-family: 'Manrope', sans-serif;
    font-size: large;
  }
  
  /* Image styles */
  #image {
    width: 100%;
    transition: filter 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  /* Overlay styles */
  .overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Hidden text styles */
  .hidden-text {
    display: none;
    text-align: center;
    justify-content: space-between;
    color: white;
    margin-bottom: 10px;
  }

  .hidden-text h1 {
    margin-top: 25px;
    color: grey;
  }
  
  /* GitHub icon styles */
  .github {
    pointer-events: auto;
    text-decoration: none;
    color: white;
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Hover effect for the image */
  .container:hover #image {
    filter: brightness(0.8); /* Darken the image */
  }
  
  /* Hover effect for the overlay */
  .container:hover .overlay {
    opacity: 1;
  }
  
  /* Show hidden text and GitHub icon on hover */
  .container:hover .hidden-text {
    display: block;
  }
  
  .container:hover .github {
    opacity: 1;
  }
  
  /*--------- Responsive Elements Phone  --------- */

  @media screen and (max-width: 576px) {
.container {
    width: 200px;
  }
}