
.flexboxcontainer1 {
    display: flex;
    background: black;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

#aboutme {
    border-bottom: solid #783D19 2px;
    flex: 3 3 200px;
    flex-direction: column;
    margin: 0 20px;
}

#headshot-container {
    flex: 1 1 300px;
    bottom: 0;
    right: 70px; 
}

#headshot-image {
    margin-left: 20%;
    width: 65%;
    bottom: 0;
}

/*--------- Responsive Elements Phone  --------- */

@media screen and (max-width: 576px) {
#aboutme {
    order: 2;
    text-align: center;
    align-items: center;
    margin: 0;
    flex: 1 1 200;
    margin-bottom: 100px;
    }

    #headshot-image {
        border-bottom: #783D19 2px solid;
    }
}