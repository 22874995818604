.info {
    text-align: center;
}

.pagebreak {
    border-top: solid white 1px;
    margin: 0 300px 0 300px;
}

ul {
    color: white;
    margin-left: 300px;
    font-family: 'Manrope', sans-serif;
}

 .experience {
    margin-left: 220px;
    margin-right: 220px;
}

.dates {
    font-weight: bolder;
}

.education {
    margin-left: 220px;
    margin-right: 220px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.download {
    margin-top: 0;
    margin-bottom: 150px;
    width: 300px;
    color: white;
    background-color: transparent;
    border-radius: 10px;
    border: none;
    font-size: medium;
    font-family: 'Manrope', sans-serif;
}

.download:hover {
    background-color: #783D19;
    color: black;
}

.download a {
    color: white;
}

.download a:hover {
    text-decoration: none;
}


@media screen and (max-width: 576px) {
    .pagebreak {
        width: 100%;
        margin: 0;
    }

    ul {
        margin-left: 0;
    }

    ul li {
        width: 100%;
    }

    .experience {
        margin: 0;
    }

    .education {
        margin: 0;
    }
}

