/*--------- Responsive Elements Phone ---------*/

@media screen and (max-width: 576px) {

    .flexboxcontainer1 {
        flex-direction: column;
        align-items: center;
    }

    h1 {
        font-size: 60px;
    }

    .flexboxcontainer6 {
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10%;
    }
    
    #horiseon-website {
        margin-top: 7%;
    }
    
    .centered {
        margin-bottom: 10%;
    }
    

    .flexboxcontainer3 {
        flex-direction: column;
    }
}
