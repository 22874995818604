html, body {
    background-color: black;
    min-height: 100vh;
    margin: 0;
}

p {
    color: whitesmoke;
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Manrope', sans-serif;
}

h1 {
    font-family: 'Manrope', sans-serif;
    font-size: 60px;
    font-weight: 100;
    color: whitesmoke;
    text-align: center;
    position: relative;
    padding-top: 2%;
}

h2 {
    color: whitesmoke;
    font-family: 'Manrope', sans-serif;
    text-align: center;
    font-weight: 100;
}

h3 {
    color: whitesmoke;
    font-family: 'Manrope', sans-serif;
    text-align: center;
    font-weight: 100;
}

span {
    font-family: 'Manrope', sans-serif;
    color:#783D19;
}

article {
    margin-left:10%;
    color: whitesmoke;
    font-family: Arial, Helvetica, sans-serif;   
}
