.webimages {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.wrimg {
    width: 1000px;
    margin-bottom: 50px;
}

#writertitle {
 margin-bottom: 0;
}

#writerparagraph {
    text-align: center;
}