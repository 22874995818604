footer {
    display: flex;
    flex-direction: column;
    color: whitesmoke;
    background-color: black;
    font-family: 'Manrope', sans-serif;
    font-size: smaller;
    text-align: center;
    bottom: 0%;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.icons {
    display: flex;
    justify-content: center;
    /* justify-content: space-between; */
}

#github {
    margin-right: 10px;
}

#instagram {
    margin-left: 10px;
}


