nav {
    display: flex;
    background: #36454F;
    width: 100%;
    justify-content: flex-end;
    
}
nav a {
    font-family: 'Manrope', sans-serif;
    color: whitesmoke;
} 

nav ul {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin-right: 5%;
}

nav li {
    display: inline-block;
    color: whitesmoke;   
}

/*--------- nav link attributes ---------*/

a:link {
    text-decoration: none;
    }
    
    a:hover {
     color: black;
     text-decoration:overline;
    }

.active {
    color: black;
    text-decoration:overline;
}

/*--------- Responsive Elements Phone  --------- */

@media screen and (max-width: 576px) {
    nav {
        display: flex;
        text-align: center;
    }

    nav ul {
        width: 100%;
        display: flex;
        flex-wrap: no-wrap;
        align-items: center;
    }

    li {
        width: 70px;
        box-sizing: border-box;
        margin: 0 10px;
    }
}